import React, { useRef, useState } from "react";
import { Popover, IconButton, Button, Grid } from "@mui/material";
import {
  Collections as PhotoIcon,
  CameraAlt as CameraIcon,
  Description as DocumentIcon,
  Person as ContactIcon,
  PollOutlined as PollIcon,
  Draw as DrawIcon,
  AttachFile as AttachIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import toast from "react-hot-toast";
import { AGENT_ID } from "../../app/config";

// Styled components
const MenuButton = styled(Button)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  padding: theme.spacing(2),
  width: "100%",
  height: "100%",
  color: theme.palette.text.secondary,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const IconWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& .MuiSvgIcon-root": {
    fontSize: "1.5rem",
  },
}));
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AttachmentMenu = ({ activeChat, setMessages, messages }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const fileInputRef = useRef();
  const menuItems = [
    { icon: PhotoIcon, label: "Photos" },
    // { icon: DocumentIcon, label: "Document" },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (label) => {
    if (label == "Photos") {
      fileInputRef.current.click();
    }
  };
  const fileHandler = async (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const formData = new FormData();
      formData.append("mobile_no", activeChat?.mobile_number);

      Array.from(files).forEach((file) => {
        formData.append("images[]", file); // `images[]` is the key expected by the API
      });
      formData.append("agent_id", AGENT_ID);
      axios
        .post("/sendImagesToCustomer", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          if (!response?.data?.error) {
            const uploadedMediaUrls = response?.data?.media_urls; // Adjust this key based on API response

            const updatedMessages = [...messages];

            const lastMessageIndex = updatedMessages.length - 1;

            if (lastMessageIndex >= 0) {
              const lastMessage = updatedMessages[lastMessageIndex];

              updatedMessages[lastMessageIndex] = {
                ...lastMessage,
                media_url: uploadedMediaUrls || lastMessage.media_url, // Append new URLs
                message_type: "outgoing",
              };
            } else {
              const nextId =
                Math.max(...messages.map((message) => message.id)) + 1 || 1;

              updatedMessages.push({
                id: nextId,
                customer_id: activeChat?.mobile_number,
                message_content: null,
                media_url: uploadedMediaUrls || null,
                media_type: "image", // Adjust this if needed
                message_type: "outgoing",
                created_at: new Date().toISOString(),
                created_at_formatted: new Date().format("DD MMM, hh:mm A"), // Assuming you are using dayjs
              });
            }
          } else {
            let errDesc = "";
            Object.values(response?.data?.data).map((error, index) => {
              errDesc = index === 0 ? errDesc + error : errDesc + error + "\n ";
            });

            toast.error(errDesc || response?.data?.message);
          }
        })
        .catch(function (error) {
          let msg = error;
          if (error?.code == "ERR_BAD_REQUEST") {
            msg = error?.response?.data?.message;
          } else {
            msg = error?.message;
          }
          toast.error(msg);
        });
    }
  };
  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{
          transform: "rotate(45deg)",
          "&:hover": {
            backgroundColor: "action.hover",
          },
        }}
      >
        <AttachIcon />
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            // width: 320,
            p: 1,
            mt: -2,
          },
        }}
      >
        <Grid container spacing={2}>
          {menuItems.map((item, index) => (
            <Grid item xs={6} key={index}>
              <MenuButton onClick={() => handleItemClick(item.label)} fullWidth>
                {/* <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  multiple={true}
                  ref={fileInputRef}
                  name={"attachments"}
                  // hidden
                  onChange={fileHandler}
                /> */}
                <VisuallyHiddenInput
                  type="file"
                  ref={fileInputRef}
                  onChange={fileHandler}
                />
                {/* <input
                  // ref={fileInputRef}
                  className="form-control"
                  name={"attachments"}
                  type="file"
                  onChange={fileHandler}
                  accept="image/png, image/jpeg, image/webp"
                /> */}

                <IconWrapper>
                  <item.icon fontSize="small" />
                </IconWrapper>
                <span style={{ fontSize: "0.5rem" }}>{item.label}</span>
              </MenuButton>
            </Grid>
          ))}
        </Grid>
      </Popover>
    </>
  );
};

export default AttachmentMenu;
