import {
  Box,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  InputBase,
  IconButton,
  Badge,
  Divider,
  TextField,
  CircularProgress,
} from "@mui/material";

import {
  AddCircleOutline,
  CheckRounded,
  ImageOutlined,
  MoreVertRounded,
  SearchRounded,
  SendRounded,
} from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import axios from "axios";
import toast from "react-hot-toast";
import { removeChatNotificationItem } from "../../redux/counterSlice";
import { useDispatch, useSelector } from "react-redux";
import chatBg from "../../assets/chatbg.png";
import AddAttachments from "./AddAttachments";
import { isNumeric, parseMessage } from "../../app/utlils";

const ChatWindow = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "calc(100vh - 48px)",
  margin: "0 auto",
  backgroundColor: theme.palette.background.paper,
}));

const SidePanel = styled(Box)(({ theme }) => ({
  width: "350px",
  borderRight: `1px solid ${theme.palette.divider}`,
  display: "flex",
  flexDirection: "column",
}));

const ChatArea = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  height: "100%",
});

const SearchPanel = styled(Box)(({ theme }) => ({
  padding: "16px",
  backgroundColor: "#FFFFFF",
  position: "sticky",
  top: 0,
  zIndex: 10,
}));

const ContactList = styled(List)(({ theme }) => ({
  flex: 1,
  overflowY: "auto", // Allows independent scrolling for the contact list
}));

const MessageArea = styled(Box)(({ theme }) => ({
  flex: 1,
  overflowY: "auto",
  padding: theme.spacing(3),
  backgroundColor: "#e5ded8",
  backgroundImage: `url(${chatBg})`,
  backgroundAttachment: "fixed",
}));

const MessageBubble = styled(Paper)(({ isown, theme }) => ({
  padding: theme.spacing(1.5),
  maxWidth: "70%",
  width: "fit-content",
  marginBottom: theme.spacing(1),
  backgroundColor: isown ? "#dcf8c6" : "#fff",
  marginLeft: isown ? "auto" : 0,
  borderRadius: 8,
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 6,
    [isown ? "right" : "left"]: -3,
    width: 0,
    height: 0,
    borderStyle: "solid",
    borderWidth: isown ? "0 8px 8px 0" : "0 0 8px 8px",
    borderColor: isown
      ? "transparent #dcf8c6 transparent transparent"
      : "transparent transparent transparent #fff",
    transform: isown ? "rotate(45deg)" : "rotate(-45deg)",
  },
}));

function Chats() {
  const dispatch = useDispatch();
  const chatRef = useRef(null);
  const chatNotificationList = useSelector(
    (state) => state.counter?.chatNotificationList
  );

  const [activeChat, setActiveChat] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [loader, setLoader] = useState(false);

  const [users, setUsers] = useState();
  const [usersBkp, setUsersBkp] = useState();

  const [messages, setMessages] = useState();

  const scrollToBottom = () => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  };
  const handleSend = () => {
    if (newMessage.trim() && activeChat) {
      setLoader(true);
      const newMsg = {
        agent_id: 5,
        text: newMessage,
        mobile_no: activeChat?.mobile_number,
      };
      axios
        .post(`/send-whatsapp-chat-msg`, newMsg)
        .then(function (response) {
          if (response?.data?.code == 200) {
            setNewMessage("");
            response?.data?.data?.length > 0 &&
              setMessages(response?.data?.data);
          } else {
            toast.error(response?.data?.message);
          }
        })
        .catch(function (err) {
          toast.error(err?.message);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  const getCustomerMessage = (user) => {
    setLoader(true);
    axios
      .get(`/user/${user?.mobile_number}/messages`)
      .then(function (response) {
        dispatch(removeChatNotificationItem(user?.id));
        setMessages(response?.data?.data);
        setActiveChat(user);
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const getRecentChats = () => {
    setLoader(true);
    axios
      .get(`/chats`)
      .then(function (response) {
        setUsers(response?.data?.data);
        setUsersBkp(response?.data?.data);
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  useEffect(() => {
    getRecentChats();
  }, []);
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (chatNotificationList?.length > 0) {
      const hasActiveChat = !!activeChat?.mobile_number;
      getRecentChats();

      if (hasActiveChat) {
        const chatId = chatNotificationList.find(
          (x) => x?.sender === activeChat?.mobile_number
        )?.chatId;

        if (chatId) {
          getCustomerMessage(activeChat);
        }
      }
    }
  }, [chatNotificationList, activeChat?.mobile_number]);

  const handleContactListSearch = (searchQuery) => {
    if (searchQuery) {
      const filteredUsers = users?.filter((user) =>
        user.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setUsers(filteredUsers);
    } else {
      setUsers(usersBkp);
    }
  };
  return (
    <ChatWindow className="chat-container">
      <SidePanel>
        <SearchPanel>
          <Paper
            elevation={0}
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              bgcolor: "#f0f2f5",
              borderRadius: 1,
            }}
          >
            <IconButton sx={{ p: "10px" }}>
              <SearchRounded size={20} />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search or start new chat"
              onChange={(e) => handleContactListSearch(e.target?.value)}
            />
          </Paper>
        </SearchPanel>
        <ContactList>
          {users?.map((user) => {
            const formattedLastMessage = parseMessage(user?.last_message);
            return (
              <React.Fragment key={user.mobile_number}>
                <ListItem
                  button
                  selected={activeChat?.mobile_number === user.mobile_number}
                  onClick={() => getCustomerMessage(user)}
                  sx={{
                    "&.Mui-selected": { bgcolor: "#f0f2f5" },
                    "&:hover": { bgcolor: "#f5f6f6" },
                  }}
                >
                  <ListItemAvatar>
                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      variant="dot"
                      color="success"
                      invisible={!user.online}
                    >
                      <Avatar src={user.avatar} />
                    </Badge>
                  </ListItemAvatar>
                  <ListItemText
                    primary={user?.name}
                    secondary={
                      <Typography
                        component="span"
                        dangerouslySetInnerHTML={{
                          __html: formattedLastMessage,
                        }}
                        sx={{
                          fontSize: "12px !important",
                          display: "block",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "100%",
                          color: "gray",
                        }}
                      />
                    }
                    secondaryTypographyProps={{
                      noWrap: true,
                      fontSize: "12px !important",
                    }}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      ml: 1,
                      gap: 1,
                    }}
                  >
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      sx={{ fontSize: "12px !important", whiteSpace: "nowrap" }}
                    >
                      {user?.timestamp}
                    </Typography>
                    {user.unread_count > 0 && (
                      <Badge
                        badgeContent={user.unread_count}
                        color="success"
                        sx={{ mr: 1, mt: 1 }}
                      />
                    )}
                  </Box>
                </ListItem>
                <Divider variant="middle" component="li" />
              </React.Fragment>
            );
          })}
        </ContactList>
      </SidePanel>

      {activeChat ? (
        <ChatArea>
          {/* Chat Header */}
          <Box
            elevation={12}
            sx={{
              padding: "8px 16px",
              bgcolor: "#FFFFFF",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Avatar
                src={users.find((u) => u.id == activeChat)?.avatar}
                sx={{ width: "30px", height: "30px" }}
              />
              <Box>
                <Typography variant="subtitle1">{activeChat?.name}</Typography>
                {!isNumeric(activeChat?.name) && (
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "10px !important", color: "gray" }}
                  >
                    {activeChat?.mobile_number}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton>
                <SearchRounded />
              </IconButton>
              <IconButton>
                <MoreVertRounded />
              </IconButton>
            </Box>
          </Box>

          {/* Messages Area */}
          <MessageArea ref={chatRef}>
            {messages?.map((message) => {
              const formattedMessage = parseMessage(message?.message_content);
              return (
                <MessageBubble
                  key={message?.id}
                  isown={message?.message_type === "incoming"}
                >
                  <Typography
                    className="chat-text"
                    dangerouslySetInnerHTML={{ __html: formattedMessage }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      gap: 0.5,
                      mt: 0.5,
                    }}
                  >
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      className="chat-timestamp"
                    >
                      {message?.created_at_formatted}
                    </Typography>
                  </Box>
                </MessageBubble>
              );
            })}
          </MessageArea>

          {/* Input Area */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "8px 16px",
              bgcolor: "#FFFFFF",
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") handleSend();
              }}
              placeholder="Type a message"
              sx={{
                marginRight: 1,
                "& fieldset": { borderColor: "#d9d9d9" },
              }}
            />
            <AddAttachments
              activeChat={activeChat}
              setMessages={setMessages}
              messages={messages}
            />
            {loader ? (
              <CircularProgress size="20px" sx={{ mx: 2 }} />
            ) : (
              <IconButton onClick={handleSend} color="primary">
                <SendRounded />
              </IconButton>
            )}
          </Box>
        </ChatArea>
      ) : (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "text.secondary",
            fontSize: "1.5rem",
          }}
        >
          Select a chat to start messaging
        </Box>
      )}
    </ChatWindow>
  );
}

export default Chats;
