import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import LoginScreen from "../screens/login/login";
import DashboardIndex from "../screens/dashboard/DashboardIndex";
import Banner from "./../screens/home/Banner";
import Category from "./../screens/products/category/Category";
import SubCategory from "./../screens/products/sub category/SubCategory";
import Products from "./../screens/products/products/Products";
import ProductForm from "./../screens/products/products/ProductForm";
import Addons from "./../screens/products/addons/Addons";
import Flavour from "./../screens/products/flavour/Flavour";
import PreviewBanner from "./../screens/home/PreviewBanner";
import ProductsImage from "./../screens/products/products/productImage/ProductsImage";
import CategoryDiscount from "./../screens/discount/category-discount";
import ProductDiscount from "./../screens/discount/product-discount";
import Promo from "./../screens/discount/promo";
import HomeRegionSelection from "./../screens/home/home-section/HomeRegionSelection";
import City from "./../screens/region/city/City";
import Location from "./../screens/region/location/Location";
import Shop from "./../screens/region/shop/Shop";
import HomeSection from "./../screens/home/home-section/HomeSection";
import OrderList from "./../screens/order/orderList/OrderList";
import OrderDetails from "./../screens/order/orderList/OrderDetails";
import DispatchPanel from "./../screens/external/dispatch/DispatchPanel";
import ViewDispatchOrder from "./../screens/external/dispatch/ViewDispatchOrder";
import ShopPanel from "./../screens/external/shop/ShopPanel";
import ViewShopOrder from "./../screens/external/shop/ViewShopOrder";
import DriverPanel from "./../screens/external/driver/DriverPanel";
import ViewDriverOrder from "./../screens/external/driver/ViewDriverOrder";
import Discount from "./../screens/discount/discount";
import Chats from "../screens/chats/Chats";
import Bulk from "../screens/bulk/Bulk";

export const ApplicationRoutes = () => {
  const userData = useSelector((state) => state.counter?.userData);

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/login" />} />
      <Route
        exact
        path="/login"
        element={
          userData?.loggedIn ? <Navigate to="/dashboard" /> : <LoginScreen />
        }
      ></Route>

      <Route exact path="/dashboard" element={<DashboardIndex />}></Route>
      {/* BF ADMIN ROUTES */}
      <Route exact path="/banner" element={<Banner />} />
      <Route exact path="/general-banner" element={<PreviewBanner />} />
      <Route path="/home-region" element={<HomeRegionSelection />} />
      <Route path="/home-section/:regionId/:city" element={<HomeSection />} />

      <Route path="/category" element={<Category />} />
      <Route path="/sub-category" element={<SubCategory />} />
      <Route replace path="/products" element={<Products />} />
      <Route path="/products/new-product" element={<ProductForm />} />
      <Route path="/products/edit-product/:id" element={<ProductForm />} />
      <Route
        path="/products/edit-product-image/:id"
        element={<ProductsImage />}
      />
      <Route path="/addons" element={<Addons />} />
      <Route path="/flavour" element={<Flavour />} />
      <Route path="/order-list" element={<OrderList type="ongoing" />} />
      <Route
        path="/delivered-order-list"
        element={<OrderList type="delivered" />}
      />
      <Route
        path="/cancelled-order-list"
        element={<OrderList type="cancelled" />}
      />
      <Route path="/failed-order-list" element={<OrderList type="failed" />} />
      {/* <Route path="/order-list/:id" element={<OrderDetails />} /> */}
      <Route
        path="/order-list/:id/:type?/:orderIds?"
        element={<OrderDetails />}
      />

      <Route path="/dispatch-panel" element={<DispatchPanel />} />
      <Route path="/view-dispatch-order/:id" element={<ViewDispatchOrder />} />
      <Route path="/shop-panel" element={<ShopPanel />} />
      <Route path="/view-shop-order/:id" element={<ViewShopOrder />} />
      <Route path="/driver-panel" element={<DriverPanel />} />
      <Route
        path="/view-driver-order/:driverId/:orderId"
        element={<ViewDriverOrder />}
      />
      <Route path="/discount" element={<Discount />} />
      <Route path="/category-discount" element={<CategoryDiscount />} />
      <Route path="/product-discount" element={<ProductDiscount />} />
      <Route path="/promo" element={<Promo />} />
      <Route path="/city" element={<City />} />
      <Route path="/location" element={<Location />} />
      <Route path="/shop" element={<Shop />} />
      <Route path="/chats" element={<Chats />} />
      <Route path="/bulk-provision" element={<Bulk />} />
    </Routes>
  );
};
