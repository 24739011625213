import React, { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Switch,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../components/loader/Loader";
import { useForm, FormProvider } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import { yupResolver } from "@hookform/resolvers/yup";
import { grey, orange } from "@mui/material/colors";
import { FormInputText } from "../../components/form-components/FormInputText";
import SaveIcon from "@mui/icons-material/Save";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";
import {
  hideConfirmModal,
  showConfirmModal,
  showModal,
} from "../../redux/counterSlice";
import { FormAutoComplete } from "../../components/form-components/FormAutoComplete";
import { parseArray } from "../../app/utlils";
import BulkVariation from "../products/products/BulkVariation";
import BulkModal from "./BulkModal";

function Bulk() {
  const dispatch = useDispatch();
  const { taxList, regionList } = useSelector((state) => state.counter);

  const [loader, setLoader] = useState(true);
  const [formData, setFormData] = useState([]);
  const [productList, setProductList] = useState([]);
  const [open, setOpen] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const loadInitialData = () => {
    setLoader(true);
    axios
      .get("/bulk-provision")
      .then(function (response) {
        setFormData(response?.data?.data);
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
        dispatch(hideConfirmModal({ msg: "" }));
      });
  };
  const loadProducts = () => {
    setLoader(true);
    axios
      .get("/activeProductList")
      .then(function (response) {
        setProductList(response?.data?.data);
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    loadProducts();
    loadInitialData();
  }, []);

  const columns = [
    {
      name: "id",
      label: "S. No",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return <p>{+dataIndex + 1}</p>;
        },
      },
    },
    {
      name: "image",
      label: "Image",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const imageUrl =
            formData[dataIndex]?.product?.app_image?.app_product_image;
          return imageUrl ? (
            <PhotoProvider>
              <PhotoView key={dataIndex} src={imageUrl}>
                <img
                  src={imageUrl}
                  alt=""
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                />
              </PhotoView>
            </PhotoProvider>
          ) : (
            <h3>-</h3>
          );
        },
      },
    },

    {
      name: "product_name",
      label: "Addon Name",
      options: {
        customBodyRenderLite: (dataIndex) =>
          formData[dataIndex]?.product?.product_name,
      },
    },

    {
      name: "active_status",
      label: "Status",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const status = formData[dataIndex]?.active_status;
          const image = formData[dataIndex]?.image;

          return (
            <Switch
              size="small"
              color={status === 1 ? "success" : "error"}
              checked={status === 1 ? true : false}
              onChange={() => {
                dispatch(
                  showConfirmModal({
                    msg: status === 1 ? "Deactivate" : "Activate",
                    action: () => toggleStatus(formData[dataIndex]?.id),
                  })
                );
              }}
              style={{ marginBottom: "5px" }}
            />
          );
        },
      },
    },

    {
      name: "Actions",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const status = formData[dataIndex]?.active_status;
          const image = formData[dataIndex]?.image;
          return (
            <div className="actionBtns">
              <Button
                disabled={status === 1 ? true : false}
                aria-label="edit"
                onClick={() => editClickHandler(formData[dataIndex])}
              >
                <EditIcon
                  fontSize="medium"
                  sx={{ color: status === 1 ? grey[600] : "secondary" }}
                />
              </Button>
            </div>
          );
        },
      },
    },
  ];
  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const editClickHandler = (item) => {
    setOpen(true);
    setSelectedRow({ ...item });
  };

  const toggleStatus = (id) => {
    setLoader(true);
    axios
      .get("/bulk-product-switch/" + id)
      .then(function () {
        loadInitialData();
      })
      .catch(function (err) {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    viewColumns: true,
    customSearch: false,
    print: false,
    searchText: searchText,
    onSearchChange: (searchValue) => setSearchText(searchValue),
  };
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <h4 className="text-dark">Bulk Provisioning</h4>

          <MUIDataTable
            data={formData?.length > 0 ? formData : []}
            columns={columns}
            options={options}
            title={
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  setSelectedRow(null);
                  setOpen(true);
                }}
                icon={<AddIcon />}
              >
                New Product
              </Button>
            }
          />
        </>
      )}
      {productList?.length > 0 && (
        <BulkModal
          data={productList}
          open={open}
          handleClose={handleClose}
          selectedRow={selectedRow}
          loadInitialData={loadInitialData}
        />
      )}

      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {selectedRow?.confirmLabel} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmOpen(false)}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={() => selectedRow?.action()}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Bulk;
