// pusherReceiver.jsx
import { useEffect } from "react";
import Pusher from "pusher-js";
import { useDispatch } from "react-redux";
import {
  addChatNotificationList,
  addNotificationList,
} from "./../redux/counterSlice";
import toast from "react-hot-toast";
import notificationSound from "./../assets/audio/new_order_received.mp3";
import chatNotification from "./../assets/audio/message-incoming.mp3";

const usePusherReceiver = () => {
  const dispatch = useDispatch();
  const audio = new Audio(notificationSound);
  const chatAudio = new Audio(chatNotification);
  audio.load();
  chatAudio.load();
  let soundEnabled = true;

  const enableSound = () => {
    audio
      .play()
      .then(() => {
        audio.pause();
        soundEnabled = true;
      })
      .catch((error) => {
        console.error("User interaction required to play audio:", error);
      });
    chatAudio
      .play()
      .then(() => {
        chatAudio.pause();
        soundEnabled = true;
      })
      .catch((error) => {
        console.error("User interaction required to play chatAudio:", error);
      });
  };

  useEffect(() => {
    // Set up user interaction to enable sound
    // window.addEventListener("click", enableSound, { once: true });
    // window.addEventListener("touchstart", enableSound, { once: true });

    // Initialize Pusher
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: "ap2",
    });

    // Subscribe to admin-event channel
    const adminChannel = pusher.subscribe("blaack-forest-app-channel");
    adminChannel.bind("admin-event", (data) => {
      dispatch(addNotificationList(data));
      playSoundWithFallback();
    });

    // Subscribe to chat-event channel
    const chatChannel = pusher.subscribe("blaack-forest-chat-channel");
    chatChannel.bind("chat-event", (data) => {
      dispatch(addChatNotificationList(data));
      playChatSoundWithFallback();
    });

    // Function to play sound with fallback notifications
    const playSoundWithFallback = () => {
      if (soundEnabled) {
        audio.play().catch((error) => {
          toast.error("Failed to play audio:", error);
        });
      } else {
        toast.error("Audio playback is blocked until user interaction.");
      }
    };
    const playChatSoundWithFallback = () => {
      if (soundEnabled) {
        chatAudio.play().catch((error) => {
          toast.error("Failed to play audio:", error);
        });
      } else {
        toast.error("Audio playback is blocked until user interaction.");
      }
    };

    // Cleanup on unmount
    return () => {
      pusher.unsubscribe("blaack-forest-app-channel");
      pusher.unsubscribe("blaack-forest-chat-channel");
    };
  }, [dispatch, audio, chatAudio]);

  return null; // This hook does not render anything itself
};

export default usePusherReceiver;
