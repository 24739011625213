import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AppShortcutOutlinedIcon from "@mui/icons-material/AppShortcutOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import CakeOutlined from "@mui/icons-material/CakeOutlined";
import CelebrationOutlined from "@mui/icons-material/CelebrationOutlined";
import IcecreamIconOutlined from "@mui/icons-material/IcecreamOutlined";
import FitScreenOutlinedIcon from "@mui/icons-material/FitScreenOutlined";
import ViewCarouselOutlinedIcon from "@mui/icons-material/ViewCarouselOutlined";
import QueuePlayNextOutlinedIcon from "@mui/icons-material/QueuePlayNextOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import DeliveryDiningOutlinedIcon from "@mui/icons-material/DeliveryDiningOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import {
  CancelOutlined,
  DiscountOutlined,
  ExitToApp,
  ExitToAppOutlined,
  LocationCity,
  PercentOutlined,
  PlaceOutlined,
  PrecisionManufacturingOutlined,
  RedeemOutlined,
  Shop2Outlined,
  SmsFailedOutlined,
  SportsMotorsportsOutlined,
  StoreOutlined,
  StorefrontOutlined,
  AddchartRoundedIcon,
  ChatOutlined,
  Inventory2Outlined,
} from "@mui/icons-material";
import AccountMenu from "./account";
import Notification from "./notification";
import { useEffect } from "react";
import ChatNotification from "./ChatNotification";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const mainMenu = [
  {
    key: "dashboard",
    text: "Dashboard",
    icon: BarChartOutlinedIcon,
    subMenu: false,
    open: false,
    route: "/dashboard",
  },
  {
    key: "home",
    text: "Home",
    icon: HomeOutlinedIcon,
    subMenu: true,
    open: false,
    items: [
      // {
      //   key: "banner",
      //   text: "Banner",
      //   route: "/banner",
      //   icon: ViewCarouselOutlinedIcon,
      // },
      // {
      //   key: "general-banner",
      //   text: "Preview Banner",
      //   route: "/general-banner",
      //   icon: FitScreenOutlinedIcon,
      // },
      {
        key: "home-section",
        text: "Home Sections",
        route: "/home-region",
        icon: AppShortcutOutlinedIcon,
      },
    ],
  },
  {
    key: "provisioning",
    text: "Provisioning",
    icon: AppsOutlinedIcon,
    subMenu: true,
    open: false,
    items: [
      {
        key: "category",
        text: "Category",
        route: "/category",
        icon: CategoryOutlinedIcon,
      },
      {
        key: "sub-category",
        text: "Sub Category",
        route: "/sub-category",
        icon: AccountTreeOutlinedIcon,
      },
      {
        key: "flavour",
        text: "Flavour",
        route: "/flavour",
        icon: IcecreamIconOutlined,
      },
      {
        key: "products",
        text: "Products",
        route: "/products",
        icon: CakeOutlined,
      },
      {
        key: "addons",
        text: "Addons",
        route: "/addons",
        icon: CelebrationOutlined,
      },
      {
        key: "bulk-provision",
        text: "Bulk Provision",
        route: "/bulk-provision",
        icon: Inventory2Outlined,
      },
    ],
  },
  {
    key: "order",
    text: "Order",
    icon: QueuePlayNextOutlinedIcon,
    subMenu: true,
    open: false,
    items: [
      {
        key: "order-list",
        text: "Ongoing Orders",
        route: "/order-list",
        icon: InventoryOutlinedIcon,
      },
      {
        key: "delivered-order-list",
        text: "Delivered Orders",
        route: "/delivered-order-list",
        icon: DeliveryDiningOutlinedIcon,
      },
      {
        key: "cancelled-order-list",
        text: "Cancelled Orders",
        route: "/cancelled-order-list",
        icon: CancelOutlined,
      },
      {
        key: "failed-order-list",
        text: "Failed Orders",
        route: "/failed-order-list",
        icon: SmsFailedOutlined,
      },
    ],
  },
  {
    key: "external",
    text: "External",
    icon: ExitToAppOutlined,
    subMenu: true,
    open: false,
    items: [
      {
        key: "dispatch-panel",
        text: "Dispatch Panel",
        route: "/dispatch-panel",
        icon: PrecisionManufacturingOutlined,
      },
      {
        key: "shop-panel",
        text: "Shop Panel",
        route: "/shop-panel",
        icon: StoreOutlined,
      },
      {
        key: "driver-panel",
        text: "Driver Panel",
        route: "/driver-panel",
        icon: SportsMotorsportsOutlined,
      },
    ],
  },
  {
    key: "discount",
    text: "Discount",
    icon: PercentOutlined,
    subMenu: true,
    open: false,
    items: [
      {
        key: "discount",
        text: "Discount",
        route: "/discount",
        icon: DiscountOutlined,
      },
      {
        key: "category-discount",
        text: "Category Discount",
        route: "/category-discount",
        icon: CategoryOutlinedIcon,
      },
      {
        key: "product-discount",
        text: "Product Discount",
        route: "/product-discount",
        icon: CakeOutlined,
      },
      {
        key: "promo",
        text: "Promo",
        route: "/promo",
        icon: RedeemOutlined,
      },
    ],
  },
  {
    key: "region",
    text: "Region",
    icon: LocationCity,
    subMenu: true,
    open: false,
    items: [
      {
        key: "city",
        text: "City",
        route: "/city",
        icon: LocationCity,
      },
      {
        key: "location",
        text: "Location",
        route: "/location",
        icon: PlaceOutlined,
      },
      {
        key: "shop",
        text: "Shop",
        route: "/shop",
        icon: StorefrontOutlined,
      },
    ],
  },
];
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideNav() {
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const currentURL = sessionStorage.getItem("baseURL");

  const theme = useTheme();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [productOpen, setProductOpen] = React.useState(false);
  const [galaryOpen, setGalaryOpen] = React.useState(false);
  const [orderOpen, setOrderOpen] = React.useState(false);
  const [ext, setExt] = React.useState(false);
  const [discount, setDiscount] = React.useState(false);
  const [menuItems, setMenuItems] = React.useState(mainMenu);

  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuClick = (index) => {
    const updatedMenuItems = menuItems.map((item, i) => {
      if (i === index) {
        return { ...item, open: !item.open };
      } else {
        return { ...item, open: false };
      }
    });
    setMenuItems(updatedMenuItems);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{
          background:
            "radial-gradient(circle at 10% 20%, rgb(226, 37, 37) 0%, rgb(211, 49, 49) 82.8%)",
          height: "48px",
        }}
      >
        <Toolbar className="d-flex justify-content-between" variant="dense">
          <div className="d-flex align-items-center">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            {/* <Typography variant="h6" noWrap component="div">
              Blaack Forest Cakes - Admin
            </Typography> */}
          </div>
          <div className="d-flex align-items-center">
            <ChatNotification />
            <Notification />
            <AccountMenu />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img
            src={require("../../assets/logo.png")}
            alt=""
            width={100}
            className="m-auto"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/dashboard")}
          />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <>
          <List>
            {menuItems.map((item, index) => (
              <React.Fragment key={item.key}>
                {!item.subMenu ? (
                  <ListItem button onClick={() => navigate(item?.route)}>
                    <ListItemIcon>
                      <item.icon sx={{ color: "grey" }} />
                    </ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItem>
                ) : (
                  <>
                    <ListItemButton onClick={() => handleMenuClick(index)}>
                      <ListItemIcon>
                        {/* <item.icon sx={{ color: "red" }} /> */}
                        {item.open ? (
                          <ExpandMoreIcon />
                        ) : (
                          <item.icon sx={{ color: "grey" }} />
                        )}
                      </ListItemIcon>
                      <ListItemText primary={`${item.text}`} />
                      {/* {item.open ? <ExpandLessIcon /> : <ExpandMoreIcon />} */}
                    </ListItemButton>
                    <Collapse in={item.open} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {item.items.map((subItem) => (
                          <ListItem
                            sx={{ px: open ? "32px" : "16px" }}
                            key={subItem.key}
                            button
                            onClick={() => navigate(subItem?.route)}
                            // sx={{ pl: 4 }}
                          >
                            <ListItemIcon>
                              <subItem.icon sx={{ color: "red" }} />
                            </ListItemIcon>
                            <ListItemText primary={subItem.text} />
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </>
                )}
              </React.Fragment>
            ))}
          </List>
        </>
      </Drawer>
    </Box>
  );
}
//SIDENAV
