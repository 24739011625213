import { memo, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import MUIDataTable from "mui-datatables";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SaveIcon from "@mui/icons-material/Save";
import { FormInputText } from "../../components/form-components/FormInputText";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { FormAutoComplete } from "../../components/form-components/FormAutoComplete";
import toast from "react-hot-toast";
import axios from "axios";
import { FormInputDropdown } from "../../components/form-components/FormInputDropdown";

function BulkModal({
  data,
  open,
  handleClose,
  selectedRow: selectedProductRow,
  loadInitialData,
}) {
  const [loader, setLoader] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [weightList, setWeightList] = useState([]);

  const methods = useForm();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "bulk_provision",
  });
  const selectedProductWatch = watch("product_id");
  const selectedWeightWatch = watch("weight_id");

  let columns = [
    {
      name: "id",
      label: "ID",
      options: {
        display: false,
        setCellProps: () => ({
          style: { width: "30px" },
        }),
        customBodyRenderLite: (dataIndex) => {
          return (
            <FormInputText
              size={"small"}
              key={fields[dataIndex]?.id}
              name={`bulk_provision[${dataIndex}].id`}
              control={control}
              label="ID"
              disabled
              mandatory="true"
              disable={true}
              defaultValue={fields[dataIndex].variation_id}
            />
          );
        },
      },
    },
    {
      name: "weight_id",
      label: "Weight",
      options: {
        setCellProps: () => ({
          style: { width: "100px" },
        }),
        customBodyRenderLite: (dataIndex) => {
          return (
            <FormInputDropdown
              size={"small"}
              name={`bulk_provision[${dataIndex}].weight_id`}
              control={control}
              label="Weight"
              type="single"
              options={weightList || []}
              errors={errors}
              defaultValue={fields[dataIndex].weight_id || ""}
              mandatory="true"
            />
          );
        },
      },
    },
    {
      name: "min_quantity",
      label: "Min Qty",
      options: {
        setCellProps: () => ({
          style: { width: "100px" },
        }),
        customBodyRenderLite: (dataIndex) => {
          return (
            <FormInputText
              size={"small"}
              key={fields[dataIndex]?.id}
              name={`bulk_provision[${dataIndex}].min_quantity`}
              control={control}
              label="Min Quantity"
              mandatory="true"
              defaultValue={fields[dataIndex].min_quantity}
            />
          );
        },
      },
    },
    {
      name: "max_quantity",
      label: "Max Qty",
      options: {
        setCellProps: () => ({
          style: { width: "100px" },
        }),
        customBodyRenderLite: (dataIndex) => {
          return (
            <FormInputText
              size={"small"}
              key={fields[dataIndex]?.id}
              name={`bulk_provision[${dataIndex}].max_quantity`}
              control={control}
              label="Max Quantity"
              mandatory="true"
              defaultValue={fields[dataIndex].max_quantity}
            />
          );
        },
      },
    },
    {
      name: "egg_price",
      label: "Egg Price",
      options: {
        setCellProps: () => ({
          style: { width: "100px" },
        }),
        customBodyRenderLite: (dataIndex) => {
          return (
            <FormInputText
              size={"small"}
              key={fields[dataIndex]?.id}
              name={`bulk_provision[${dataIndex}].egg_price`}
              control={control}
              label="Price"
              mandatory="true"
              defaultValue={fields?.[dataIndex]?.egg_price}
            />
          );
        },
      },
    },
    {
      name: "eggless_price",
      label: "Eggless Price",
      options: {
        setCellProps: () => ({
          style: { width: "100px" },
        }),
        customBodyRenderLite: (dataIndex) => {
          return (
            <FormInputText
              size={"small"}
              key={fields[dataIndex]?.id}
              name={`bulk_provision[${dataIndex}].eggless_price`}
              control={control}
              label="Price"
              mandatory="true"
              defaultValue={fields?.[dataIndex]?.eggless_price}
            />
          );
        },
      },
    },
    {
      name: "Delete",
      options: {
        setCellProps: () => ({
          style: { width: "100px" },
        }),
        customBodyRenderLite: (dataIndex) => {
          return (
            <div className="actionBtns">
              <Button
                key={fields[dataIndex]?.id}
                aria-label="edit"
                onClick={() => removeHandler(dataIndex)}
              >
                <DeleteOutlineOutlinedIcon color="error" fontSize="medium" />
              </Button>
            </div>
          );
        },
      },
    },
  ];
  const addVariationHandler = () => {
    append({
      weight_id: "",
      min_quantity: 1,
      max_quantity: 1,
      egg_price: null,
      eggless_price: null,
    });
  };
  const removeHandler = (index) => {
    remove(index);
  };
  const onSubmit = async (data) => {
    const payload = {
      product_id: data?.product_id?.value,
      // weight_id: data?.weight_id,
      bulk_provision: data?.bulk_provision,
    };
    try {
      setLoader(true);
      let response;
      if (selectedRow?.id > 0) {
        response = await axios.put(
          "/bulk-provision/" + selectedRow?.id,
          payload
        );
      } else {
        response = await axios.post("/bulk-provision", payload);
      }
      if (!response?.data?.error) {
        toast.success(response?.data?.message);
        loadInitialData();
      } else {
        let errDesc = "";
        if (response?.data?.data) {
          Object.values(response?.data?.data).map((error, index) => {
            errDesc =
              index === 0 ? errDesc + error + "\n " : errDesc + error + "\n ";
          });
        } else {
          errDesc = response?.data?.message;
        }
        toast.error(errDesc);
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoader(false);
      handleClose();
    }
  };
  const options = {
    selectableRows: false,
    filter: false,
    download: false,
    viewColumns: false,
    print: false,
    search: false,
    pagination: false,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
  };
  useEffect(() => {
    selectedProductRow && setSelectedRow(selectedProductRow);
  }, [selectedProductRow]);

  useEffect(() => {
    const selectedProduct =
      selectedProductWatch?.value || selectedProductRow?.product_id;

    if (selectedProduct) {
      const selectedProductObj = data?.find(
        (product) => product?.id === selectedProduct
      );

      if (selectedProductObj) {
        const unit = selectedProductObj?.unit?.unit_name;

        const newWeightList = selectedProductObj?.weight_ids?.map((item) => ({
          id: item?.id,
          name: `${item?.itemName} ${unit}`,
        }));
        setWeightList((prev) =>
          JSON.stringify(prev) !== JSON.stringify(newWeightList)
            ? newWeightList
            : prev
        );
      }
    }
    if (selectedProductRow) {
      remove();
      selectedProductRow?.bulk_price?.forEach((item) => {
        console.log(item);
        append(item);
        // if (item?.weight_id == selectedWeightWatch) {
        //   append(item);
        // } else {
        //   remove(item?.id);
        // }
      });
    } else {
      remove();
    }
  }, [data, selectedProductRow, selectedProductWatch, selectedWeightWatch]);
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"md"}>
      <DialogTitle className="text-primary">Bulk Provision</DialogTitle>
      <DialogContent>
        <Grid container columnSpacing={{ xs: 5 }}>
          <Grid item xs={6}>
            <FormAutoComplete
              name="product_id"
              control={control}
              label="Product"
              multiple={false}
              dataArray={data?.map((item) => ({
                value: item?.id,
                label: item?.product_name,
              }))}
              errors={errors}
              defaultValue={
                selectedProductRow && {
                  value: selectedProductRow?.product_id,
                  label: selectedProductRow?.product?.product_name,
                }
              }
              mandatory="true"
            />
          </Grid>
          {/* <Grid item xs={4}>
            <FormInputDropdown
              name="weight_id"
              control={control}
              label="Weight"
              type="single"
              options={weightList || []}
              errors={errors}
              defaultValue={selectedProductRow?.weight_id || ""}
              mandatory="true"
            />
          </Grid> */}
          <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "cente",
            }}
          >
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={() => addVariationHandler()}
              icon={<AddIcon />}
            >
              Add
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <MUIDataTable
              // dense
              className="bg-light"
              data={fields || []}
              columns={columns}
              options={options}
              columnVisibilityModel={{
                variation_id: false,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="error">
          Cancel
        </Button>
        <Button
          // disabled={!isValid}
          color="primary"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          startIcon={<SaveIcon />}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default memo(BulkModal);
