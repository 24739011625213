import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";

export const FormInputText = ({
  name,
  control,
  label,
  type,
  placeholder,
  defaultValue,
  maxLength,
  className,
  disabled,
  size,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ""}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <TextField
            fullWidth
            id="outlined-basic"
            label={label}
            size={size || "medium"}
            // helperText={errors?.[name]?.message}
            type={type || "text"}
            placeholder={placeholder}
            className={className}
            variant="outlined"
            onChange={onChange}
            defaultValue={defaultValue || value}
            maxLength={maxLength}
            error={error?.message ? true : false}
            margin="normal"
            disabled={disabled || false}
          />
        );
      }}
    />
  );
};
