import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Chip from "@mui/material/Chip";
import {
  ChatOutlined,
  CircleNotifications,
  NotificationsActiveOutlined,
} from "@mui/icons-material";
import { Badge, Typography } from "@mui/material";

export default function ChatNotification() {
  const navigate = useNavigate();
  const notificationList = useSelector(
    (state) => state.counter?.chatNotificationList
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    navigate("/chats");
  };

  return (
    <React.Fragment>
      <>
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2, color: "white" }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Badge badgeContent={notificationList?.length} color="success">
                <ChatOutlined fontSize="medium" />
              </Badge>
            </IconButton>
          </Tooltip>
        </Box>
      </>
    </React.Fragment>
  );
}
