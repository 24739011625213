import "./App.css";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-photo-view/dist/react-photo-view.css";
import axios from "axios";
import { Toaster } from "react-hot-toast";
import AlertDialog from "./components/alert/AlertDialog";
import { Box, styled } from "@mui/material";
import SideNav from "./layout/sideNav/sideNav";
import { useSelector } from "react-redux";
import ConfirmDialog from "./components/alert/ConfirmDialog";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import useSessionStorage from "./hooks/useSessionStorage";
import usePusherReceiver from "./app/pusherReceiver";
import { ApplicationRoutes } from "./app/routes";

const AppContent = () => {
  const location = useLocation(); // Use useLocation here
  const isChatsRoute = location.pathname === "/chats"; // Check if the current route is "/chats"

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));

  return (
    <Box sx={{ display: "flex" }}>
      <SideNav />
      <Box component="main" sx={{ flexGrow: 1, p: isChatsRoute ? 0 : 3 }}>
        <DrawerHeader />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ApplicationRoutes />
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

function App() {
  usePusherReceiver();
  const userData = useSelector((state) => state.counter?.userData);

  const [baseURL, setBaseURL] = useSessionStorage(
    "baseURL",
    "https://app.blaackforestcakes.com/api/"
  );

  axios.defaults.baseURL = baseURL;
  // axios.defaults.baseURL = "http://192.168.95.28:8000/api/";

  return (
    <Router>
      <Toaster
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <AlertDialog />
      <ConfirmDialog />
      {userData?.loggedIn ? <AppContent /> : <ApplicationRoutes />}
    </Router>
  );
}

export default App;
